<div class="sl-flexbox sl-flexbox-align-center">
	<div>
		@if (labelSignal$()) {
			<div class="sl-slide-toggle__label sl-flexbox">
				<span>{{ labelSignal$() }}</span>
				@if (linkSignal$()) {
					<a	class="sl-slide-toggle__link new-design small-action"
						mat-icon-button
						target="_blank"
						[pTooltip]="linkTooltipSignal$()"
						tooltipPosition="bottom"
						tooltipStyleClass="sl-v1-tooltip"
						[routerLink]="linkSignal$()"
						(click)="linkClick.emit()">
						<mat-icon>open_in_new</mat-icon>
					</a>
				}
			</div>
		}
		@if (descriptionSignal$()) {
			<div class="sl-slide-toggle__description">{{ descriptionSignal$() }}</div>
		}
	</div>
	<p-inputSwitch styleClass="new-design" (onChange)="change($event)"></p-inputSwitch>
</div>
<ng-content></ng-content>
