import { ChangeDetectionStrategy, Component, HostBinding, input, OnChanges, output, SimpleChanges, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { InputSwitch, InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';

type SlideToggleSize = 'small' | 'normal' | 'large';

@Component({
	selector: 'sl-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrl: './slide-toggle.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		InputSwitchModule,
		TooltipModule,
		RouterLink
	],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SlideToggleComponent,
		multi: true
	}],
})
export class SlideToggleComponent implements ControlValueAccessor, OnChanges {
	readonly labelSignal$ = input<string>(null, { alias: 'label' });
	readonly descriptionSignal$ = input<string>(null, { alias: 'description' });
	readonly linkSignal$ = input<string>(null, { alias: 'link' });
	readonly linkTooltipSignal$ = input<string>(null, { alias: 'linkTooltip' });
	readonly checkedSignal$ = input<boolean>(false, { alias: 'checked' });
	readonly sizeSignal$ = input<SlideToggleSize>('normal', { alias: 'size' });
	readonly disabledSignal$ = input<boolean>(false, { alias: 'disabled' });

	readonly toggled = output<{ originalEvent: MouseEvent; checked: boolean }>();
	readonly linkClick = output<void>();

	readonly inputSwitch = viewChild.required<InputSwitch>(InputSwitch);

	onChangeFunction: (fn: any) => void;
	onTouchedFunction: () => void;

	@HostBinding('class') get hostClasses(): object {
		const size = this.sizeSignal$() || 'normal';
		return {
			'sl-slide-toggle': true,
			['sl-slide-toggle--' + size]: true,
			['sl-slide-toggle--no-content']: !this.labelSignal$() && !this.descriptionSignal$()
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.checkedSignal$) {
			this.writeValue(this.checkedSignal$());
		}

		if (changes.disabled) {
			this.setDisabledState(this.disabledSignal$());
		}
	}

	change(options: { originalEvent: MouseEvent; checked: boolean }): void {
		this.toggled.emit(options);
	}

	writeValue(checked: boolean): void {
		this.inputSwitch().writeValue(checked);
	}

	setDisabledState(isDisabled: boolean): void {
		this.inputSwitch().setDisabledState(isDisabled);
	}

	registerOnChange(fn: () => void): void {
		this.onChangeFunction = fn;
		this.inputSwitch().registerOnChange(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFunction = fn;
		this.inputSwitch().registerOnTouched(fn);
	}
}
